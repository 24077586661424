import React, { useRef, useState, useEffect } from 'react';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Seo from "../../components/common/seo";
import Layout from "../../components/common/layout";
import { Link, graphql } from "gatsby";
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { fullDate } from '../../utils/date_format';
import moment from 'moment';

const ResourceDetail = ({ data }) => {
    function monthYearDate(dateString) {
        return moment(dateString).format('MMM YYYY');
    }

    function dayDate(dateString) {
        return moment(dateString).format('DD');
    }

    const resource = data.contentfulResourceCentre;
    const resources = data.allContentfulResourceCentre.nodes;

    const [selectedTags, setSelectedTags] = useState([]);

    const groupResourcesByTag = (resources) => {
        const grouped = {};
        resources.forEach(resource => {
            resource.newTags.forEach(tag => {
                if (!grouped[tag]) {
                    grouped[tag] = [];
                }
                grouped[tag].push(resource);
            });
        });
        return grouped;
    };

    const groupedResources = groupResourcesByTag(resources);

    const getLimitedResources = (groupedResources, selectedTags) => {
        let filteredResources = [];
        if (selectedTags.length === 0) {
            // If no tags are selected, include all resources
            Object.keys(groupedResources).forEach(tag => {
                filteredResources = [...filteredResources, ...groupedResources[tag]];
            });
        } else {
            // Filter resources based on selected tags
            selectedTags.forEach(tag => {
                if (groupedResources[tag]) {
                    filteredResources = [...filteredResources, ...groupedResources[tag]];
                }
            });
        }
        return filteredResources.slice(0, 16);
    };

    const limitedResources = getLimitedResources(groupedResources, selectedTags);

    const swiperRef = useRef(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const goNext = () => {
        if (swiperRef.current && !swiperRef.current.isEnd) {
            swiperRef.current.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && !swiperRef.current.isBeginning) {
            swiperRef.current.slidePrev();
        }
    };

    const checkSwiperState = () => {
        if (swiperRef.current) {
            setIsBeginning(swiperRef.current.isBeginning);
            setIsEnd(swiperRef.current.isEnd);
        }
    };

    useEffect(() => {
        checkSwiperState();
        swiperRef.current?.on('slideChange', checkSwiperState);
        return () => {
            swiperRef.current?.off('slideChange', checkSwiperState);
        };
    }, []);

    const handleClick = (e, tag) => {
        e.preventDefault();
        setSelectedTags((prevTags) => {
            if (prevTags.includes(tag)) {
                return prevTags.filter((selectedTag) => selectedTag !== tag);
            } else {
                return [...prevTags, tag];
            }
        });
    };

    const handleViewAllClick = (e) => {
        e.preventDefault();
        setSelectedTags([]);
    };

    return (
        <div className="resourcesCenterPage">
            <Layout>
                <Seo title={resource?.title} />
                <div className="resourceDetailsContentWrapper">
                    <div className="resourceDetailsHero">
                        <div className="container">
                            <Link className="backToResourcesBtn" to="/resources">
                                <span className="leftArrowCaret">
                                    &#9664;
                                </span> Back to Resource Hub
                            </Link>
                        </div>
                    </div>
                    <div className="resourceDetailsContent">
                        <div className="container">
                            <div className="resourceDetailsHeroCard">
                                <div className="pillsCategoryWrapper">
                                    {resource?.newTags.map((tag, index) => (
                                        <span key={index} className="pillsCategory">{tag}</span>
                                    ))}
                                </div>
                                <img src={resource?.image?.file?.url} />
                                <h1>{resource?.title}</h1>
                                <p className="lead">{resource?.excerpt?.excerpt}</p>
                                <span className="resourceCOMMBCallout">
                                    <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="17.5" cy="17.6738" r="16.5" fill="white" stroke="white" strokeWidth="2" />
                                        <path d="M17.5105 7.17383H17.4763C16.6249 7.17473 15.782 7.33463 14.9957 7.64441C14.2095 7.9542 13.4953 8.40779 12.8939 8.9793C12.2925 9.55081 11.8157 10.229 11.4908 10.9753C11.1658 11.7215 10.9991 12.5211 11 13.3285C11.0722 14.7231 11.5086 16.0789 12.2701 17.274C13.4694 19.0663 16.981 26.577 17.4955 27.153C17.4955 27.153 17.5063 27.1803 17.5129 27.1724C17.5189 27.1798 17.5303 27.153 17.5303 27.153C18.043 26.5759 21.5438 19.0583 22.7407 17.2632C23.4991 16.0662 23.9318 14.7092 24 13.3142C23.9981 11.684 23.3133 10.1212 22.0963 8.96969C20.8793 7.81815 19.2298 7.17217 17.5105 7.17383ZM17.5063 17.8932C16.5786 17.8943 15.6714 17.6344 14.8995 17.1465C14.1275 16.6586 13.5255 15.9647 13.1695 15.1524C12.8135 14.34 12.7195 13.4459 12.8995 12.5829C13.0794 11.72 13.5252 10.927 14.1804 10.3042C14.8356 9.68151 15.6709 9.257 16.5805 9.08439C17.4902 8.91179 18.4334 8.99884 19.2909 9.33453C20.1484 9.67023 20.8816 10.2395 21.3979 10.9703C21.9142 11.7012 22.1904 12.5608 22.1914 13.4405C22.1927 14.62 21.6998 15.7517 20.8212 16.5868C19.9427 17.4218 18.7503 17.8917 17.5063 17.8932Z" fill="#BF1116" />
                                    </svg>
                                    {/* {resource?.author} */}
                                    Canadian Out-of-Home Marketing and Measurement Bureau
                                </span>
                                <a href={resource?.fileUrl} className="resourceDownloadBtn" target="_blank">
                                    Download
                                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8667 9.74609V12.8658H1.13333V9.74609H0V14.0002H12V9.74609H10.8667Z" fill="#BEBEBE" />
                                        <path d="M2.94424 7.03327L6.04175 10.6946L9.07214 7.03328L6.62098 7.03328L6.62098 -1.06623e-07L5.3954 -1.59935e-07L5.3954 7.03327L2.94424 7.03327Z" fill="#BEBEBE" />
                                        <path d="M5.91166 0.514061L5.91166 7.54734L4.05316 7.54734L6.04176 9.89156L7.97977 7.54734L6.10474 7.54734L6.10474 0.514061L5.91166 0.514061Z" fill="#BEBEBE" />
                                    </svg>
                                </a>
                            </div>
                            <div className="resourceDetailsMainContent">
                                {renderRichText(resource?.content)}
                            </div>
                            <a href={resource?.fileUrl} className="resourceDownloadBtn" target="_blank">
                                Download
                                <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.5833 13.1064V17.0061H1.41667V13.1064H0V18.4241H15V13.1064H13.5833Z" fill="white" />
                                    <path d="M3.68069 9.71542L7.55258 14.2921L11.3406 9.71542L8.27662 9.71542L8.27662 0.923828L6.74465 0.923828L6.74465 9.71542L3.68069 9.71542Z" fill="white" />
                                    <path d="M7.38998 1.5664L7.38998 10.358L5.06684 10.358L7.5526 13.2883L9.9751 10.358L7.63132 10.358L7.63132 1.5664L7.38998 1.5664Z" fill="white" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="relatedResourcesWrapper">
                    <div className="container">
                        <h3>Related Resources</h3>
                        <div className="relatedTagButtonsWrapper">
                            {resources?.length > 0 && resources[0].tags?.length > 0 && resources[0].tags.map(tag => (
                                <a key={tag} href="#" onClick={(e) => handleClick(e, tag)} className={selectedTags.includes(tag) ? 'active' : ''}>{tag}</a>
                            ))}
                            <a href="#" className={`viewAllResourcesBtn ${selectedTags.length === 0 ? 'active' : ''}`} onClick={(e) => handleViewAllClick(e)}>
                                View All
                            </a>
                        </div>
                        <div className="relatedResourceCardGrid">
                            <button onClick={goPrev} className="relatedResourcePrevBtn" disabled={isBeginning}>◀</button>
                            <button onClick={goNext} className="relatedResourceNextBtn" disabled={isEnd}>▶</button>
                            <Swiper
                                modules={[Navigation]}
                                spaceBetween={15}
                                slidesPerView={4}
                                onSwiper={(swiper) => (swiperRef.current = swiper)}
                            >
                                {limitedResources?.length > 0 && limitedResources.filter(r => r.image !== null).map((item, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="resourceGridCard">
                                            <Link to={`/resource-centre/${item.slug}`}>
                                                <img src={item.image?.file?.url} />
                                            </Link>
                                            <span className="dateImgCard">
                                                {dayDate(item.date)}
                                                <small>{monthYearDate(item.date)}</small>
                                            </span>
                                            <div className="resourceCardDetails">
                                                {item.newTags.map((tag, index) => (
                                                    <span key={index} className="pillsCategory">{tag}</span>
                                                ))}
                                                <h3>
                                                    <Link to={`/resource-centre/${item.slug}`}>
                                                        {item.title}
                                                    </Link>
                                                </h3>
                                                <hr />
                                                {/* <p className="relatedCOMMBCallout">{item.author}</p> */}
                                                <div className="resourceCardButtonWrapper">
                                                    <a href={`/resource-centre/${item.slug}`} className="resourceMoreDetailsBtn">
                                                        More Details
                                                    </a>
                                                    <a href={item.fileUrl} className="resourceDownloadBtn" target="_blank">
                                                        Download
                                                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.8667 9.74609V12.8658H1.13333V9.74609H0V14.0002H12V9.74609H10.8667Z" fill="#BEBEBE" />
                                                            <path d="M2.94424 7.03327L6.04175 10.6946L9.07214 7.03328L6.62098 7.03328L6.62098 -1.06623e-07L5.3954 -1.59935e-07L5.3954 7.03327L2.94424 7.03327Z" fill="#BEBEBE" />
                                                            <path d="M5.91166 0.514061L5.91166 7.54734L4.05316 7.54734L6.04176 9.89156L7.97977 7.54734L6.10474 7.54734L6.10474 0.514061L5.91166 0.514061Z" fill="#BEBEBE" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <a className="mobileOnly backToResourcesBtn" href="/resource-centre"><span className="leftArrowCaret">◀</span> Back to Resource Hub</a>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default ResourceDetail;

export const query = graphql`
query GetResource($slug: String){
    contentfulResourceCentre(slug:{eq: $slug}){
        title
        excerpt {
            excerpt
        }
        newTags
        fileUrl
        content {
            raw
        }
        image {
            file {
                url
            }
        }
    }
    allContentfulResourceCentre{
        nodes{
            id
            title
            slug       
            tags
            newTags
            fileUrl
            image {
                file {
                    url
                }
            }            
        }
    }
}
`;
